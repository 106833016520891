import { GroupAndServiceDataProps } from '@/components/common/data-list'

export const groupCompanies: GroupAndServiceDataProps[] = [
  {
    title: '株式会社Relicホールディングス',
    url: 'https://relic-holdings.co.jp/',
    isTargetBlank: true
  },
  {
    title: '株式会社Relic',
    url: 'https://relic.co.jp/',
    isTargetBlank: true
  },
  {
    title: '株式会社Rebootus',
    url: 'https://relic-holdings.co.jp/group/rebootus/',
    isTargetBlank: true
  },
  {
    title: '株式会社Scalehack',
    url: 'https://scalehack.co.jp/',
    isTargetBlank: true
  },
  {
    title: '株式会社CAMPFIRE ENjiNE',
    url: 'https://relic-holdings.co.jp/group/campfire-enjine/',
    isTargetBlank: true
  },
  {
    title: '株式会社MEAL FORWARD',
    url: 'https://relic-holdings.co.jp/group/mealforward/',
    isTargetBlank: true
  },
  {
    title: '株式会社Pro3 Lab',
    url: 'https://pro3-lab.co.jp/',
    isTargetBlank: true
  },
  {
    title: '株式会社ALTILAN',
    url: 'https://relic-holdings.co.jp/group/altilan/',
    isTargetBlank: true
  }
]
